.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App-stores {
  display: flex;
  align-items: center;
  justify-content: "center";
  flex: 1;
  /* align-self: center; */
  width: 80vw;
}
.Store {
  /* background-color: #5d3fd3; */
  /* width: 20%; */
  /* flex: 1; */
  /* object-fit: cover; */
}
.Store-image {
  width: 40%;
  height: 40%;
}
@media screen and (max-width: 600px) {
  .App-logo {
    height: 70vmin;
  }
  .App-stores {
    /* flex-direction: col; */
    height: 20vmin;
    /* background-color: red; */
  }
  .Store-image {
    width: 100%;
    height: 100%;
    /* object-fit: contain; */
  }
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
